import { FloatButton, Tooltip } from "antd";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { AreaExtra, Schemes, globalArea, globalArrange, globalEditor } from "./editor";
import {
    ExpandAltOutlined,
    FileImageOutlined,
    PartitionOutlined,
    PlayCircleOutlined,
} from "@ant-design/icons";
import { AreaExtensions } from "rete-area-plugin";
import { ArrangeAppliers } from "rete-auto-arrange-plugin";
import { saveGraph } from "../helpers/importExportFunctions";
import { getDetailsFromAction, getRelevantEventData, saveUserLog } from "../helpers/nodeHelpers";
import { ClassicPreset } from "rete";

const applier = new ArrangeAppliers.TransitionApplier<Schemes, AreaExtra>({
    duration: 600,
    timingFunction: (t) => t,
    async onTick() {
        AreaExtensions.zoomAt(globalArea, globalEditor.getNodes());
    },
});

export const captureEditorLog = (action: string, relevantDetails = {}, problem_id: number) => {
    const blocks = globalEditor.getNodes().map((node) => node.getType());
    const connections = globalEditor.getConnections().map((connection) => {
        const sourceNode = globalEditor.getNode(connection.source);
        const targetNode = globalEditor.getNode(connection.target);
        return {
            source: sourceNode.getType(),
            target: targetNode.getType(),
        };
    });
    const eventData = getRelevantEventData(action, {
        editorData: { blocks, connections, ...relevantDetails },
    });
    const details = getDetailsFromAction(action, eventData);
    saveUserLog({ action, eventData, details, problem_id });
};

export const handleZoomToFit = async () => {
    await AreaExtensions.zoomAt(globalArea, globalEditor.getNodes());
};

interface EditorButtonPanelProps {
    setRunProgram: Dispatch<SetStateAction<boolean>>;
    runProgram: boolean;
    setIsImageGalleryOpen: Dispatch<SetStateAction<boolean>>;
    problem_id: number;
    consoleText: string;
    codePreview: string;
}

const EditorButtonPanel = ({
    setRunProgram,
    runProgram,
    setIsImageGalleryOpen,
    problem_id,
    consoleText,
    codePreview,
}: EditorButtonPanelProps) => {
    const [localRunProgram, setLocalRunProgram] = React.useState(false);

    const handleClickZoomToFit = async () => {
        await handleZoomToFit();
        captureEditorLog("zoom_to_fit", {}, problem_id);
    };

    const handleAutoLayout = async () => {
        await globalArrange.layout({
            applier,
        });
        saveGraph(problem_id);
        captureEditorLog("auto_layout", {}, problem_id);
    };

    const handleRunProgram = async () => {
        if (!localRunProgram) {
            setLocalRunProgram(true);
        }
    };

    const handleOpenImageGallery = () => {
        setIsImageGalleryOpen(true);
    };

    useEffect(() => {
        if (localRunProgram) {
            setRunProgram(true);
        }
    }, [localRunProgram]);

    useEffect(() => {
        if (!runProgram) {
            setLocalRunProgram(false);
            if (localRunProgram) {
                captureEditorLog(
                    "run_program",
                    {
                        code_preview: codePreview,
                        console_output: consoleText,
                    },
                    problem_id
                );
            }
        }
    }, [runProgram]);

    return (
        <div className="editorFloatButtonGroup">
            <Tooltip title="View Saved Images">
                <FloatButton icon={<FileImageOutlined />} onClick={handleOpenImageGallery} />
            </Tooltip>
            <Tooltip title="Auto-Layout">
                <FloatButton icon={<PartitionOutlined />} onClick={handleAutoLayout} />
            </Tooltip>
            <Tooltip title="Zoom to Fit">
                <FloatButton icon={<ExpandAltOutlined />} onClick={handleClickZoomToFit} />
            </Tooltip>
            <Tooltip title="Run Program">
                <FloatButton
                    className="runProgramBtn"
                    icon={<PlayCircleOutlined />}
                    onClick={handleRunProgram}
                />
            </Tooltip>
        </div>
    );
};

export default EditorButtonPanel;
