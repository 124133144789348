import {
    Button,
    Divider,
    Drawer,
    Form,
    Input,
    Select,
    Radio,
    Space,
    Row,
    Col,
    Card,
    Tag,
    Typography,
    List,
    ConfigProvider,
    theme,
} from "antd";
import React, {
    FormEvent,
    MouseEvent,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { debouncedAutoSave } from "./editor";
import {
    axiosInstance,
    delayFunction,
    handleCopyEvent,
    preventZoomOnScrollableElements,
} from "../helpers/nodeHelpers";
import "../styles/ModuleViewer.css";
import Prism from "prismjs";
import "prismjs/components/prism-python";
import "prismjs/themes/prism-okaidia.css";
import {
    ArrowLeftOutlined,
    BulbOutlined,
    ClockCircleOutlined,
    CodeOutlined,
    PlayCircleOutlined,
    VerticalAlignTopOutlined,
} from "@ant-design/icons";
import MarkdownRenderer from "./MarkdownRenderer";
import debounce from "lodash.debounce";
import ProblemContext from "../contexts/ProblemContext";
import { convertToUserLocalTime } from "./AIChatbot";

const { Option } = Select;
const { Group: RadioGroup } = Radio;
const { Text } = Typography;

interface UserModuleInterface {
    status: "in-progress" | "completed";
    is_completed: boolean;
    date_completed?: string;
}

interface ModuleInterface {
    id: number;
    module_number: string;
    title: string;
    overview: string;
    content: string;
    category: string;
    type: string;
    duration: number;
    user_module: UserModuleInterface[];
}

type Props = {
    leftColumnRef: RefObject<HTMLDivElement>;
    rightColumnRef: RefObject<HTMLDivElement>;
    openModuleViewerButtonRef: RefObject<HTMLButtonElement>;
};

const capitalizeWords = (str: string) => {
    return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

const moduleStatusToClass: { [key in "in-progress" | "completed"]: string } = {
    "in-progress": "moduleInProgress",
    completed: "moduleComplete",
};

const ModuleViewer = ({ leftColumnRef, rightColumnRef, openModuleViewerButtonRef }: Props) => {
    const [drawerHeight, setDrawerHeight] = React.useState(
        window.innerHeight < 700 ? 0.8 * window.innerHeight : 600
    );
    const [showModuleViewer, setShowModuleViewer] = useState(false);
    const [leftPosition, setLeftPosition] = useState(0);
    const [rightPosition, setRightPosition] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [query, setQuery] = useState<string>("");
    const [sortBy, setSortBy] = useState<string>("");
    const [filterType, setFilterType] = useState<string>("");
    const [filterStatus, setFilterStatus] = useState<string>("");
    const [searchResults, setSearchResults] = useState<ModuleInterface[]>([]);
    const [modules, setModules] = useState<ModuleInterface[]>([]);
    const [selectedModule, setSelectedModule] = useState<ModuleInterface | null>(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showScrollUpButton, setShowScrollUpButton] = useState(false);

    const problemContext = useContext(ProblemContext);
    if (!problemContext) {
        throw new Error("Problem Context not loaded properly.");
    }
    const { problem } = problemContext;

    const moduleListViewerRef = useRef<HTMLDivElement | null>(null);
    const singleModuleViewerRef = useRef<HTMLDivElement | null>(null);
    const startY = useRef(0);
    const startHeight = useRef(drawerHeight);
    const isDraggingRef = useRef(false);
    const queryRef = useRef(query);
    const sortByRef = useRef(sortBy);
    const filterTypeRef = useRef(filterType);
    const filterStatusRef = useRef(filterStatus);

    const closeModuleViewer = () => {
        setShowModuleViewer(false);
        if (selectedModule) {
            handleStopReadingModule();
        }
        debouncedAutoSave("close_module_viewer", {}, true);
        setTimeout(() => {
            setScrollPosition(0);
            setSelectedModule(null);
            handleClickScrollToTopButton("instant");
        }, 200);
    };

    const openModuleViewer = () => {
        setShowModuleViewer(true);
        debouncedAutoSave("open_module_viewer", {}, true);
    };

    const updateWidths = () => {
        if (leftColumnRef.current) {
            setLeftPosition(leftColumnRef.current.offsetWidth);
        }
        if (rightColumnRef.current) {
            const rightRect = rightColumnRef.current.getBoundingClientRect();
            setRightPosition(document.body.offsetWidth - rightRect.left);
        }
    };

    const onMouseDown = (e: MouseEvent) => {
        setIsDragging(true);
        isDraggingRef.current = true;
        startY.current = e.clientY;
        startHeight.current = drawerHeight;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e: any) => {
        if (isDraggingRef.current) {
            const difference = e.clientY - startY.current;
            const newHeight = startHeight.current - difference;
            setDrawerHeight(Math.max(newHeight, 350));
        }
    };

    const onMouseUp = () => {
        setIsDragging(false);
        isDraggingRef.current = false;
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    const handleStartReadingModule = async (module: ModuleInterface) => {
        if (!module || !problem) return;
        debouncedAutoSave(
            "start_reading_module",
            { module_id: module.id, module_number: module.module_number, title: module.title },
            true
        );

        // Scroll to top
        handleClickScrollToTopButton("instant");

        if (module.user_module.length === 0) {
            try {
                const res = await axiosInstance.post("/api/modules/start-reading-module", {
                    module_id: module.id,
                });
                // Update module with new user_module data if a record was created
                setModules((prevModules) =>
                    prevModules.map((prevModule) =>
                        prevModule.id === module.id
                            ? {
                                  ...prevModule,
                                  user_module: [res.data.userModule],
                              }
                            : prevModule
                    )
                );
            } catch (e) {
                console.log(e);
            }
        }
    };

    const handleStopReadingModule = async () => {
        if (!selectedModule || !problem) return;

        debouncedAutoSave(
            "stop_reading_module",
            {
                module_id: selectedModule.id,
                module_number: selectedModule.module_number,
                title: selectedModule.title,
            },
            true
        );
    };

    const handleMarkModuleAsComplete = async () => {
        if (!selectedModule || !problem) return;
        try {
            const res = await axiosInstance.post("/api/modules/complete-module", {
                module_id: selectedModule.id,
            });
            setModules((prevModules) =>
                prevModules.map((prevModule) =>
                    prevModule.id === selectedModule.id
                        ? {
                              ...prevModule,
                              user_module: [res.data.userModule],
                          }
                        : prevModule
                )
            );
            setSelectedModule((prevModule) =>
                prevModule
                    ? {
                          ...prevModule,
                          user_module: [res.data.userModule],
                      }
                    : prevModule
            );
            debouncedAutoSave(
                "mark_module_as_complete",
                {
                    module_id: selectedModule.id,
                    module_number: selectedModule.module_number,
                    title: selectedModule.title,
                    date_completed: res.data.userModule.date_completed,
                },
                true
            );
        } catch (e) {
            console.log(e);
        }
    };

    const handleSelectModule = (module: ModuleInterface | null) => {
        const moduleListViewer = document.querySelector(".moduleSearchAndResultsContainer");
        if (moduleListViewer) {
            setScrollPosition(moduleListViewer.scrollTop);
        }
        if (module) {
            handleStartReadingModule(module);
        } else {
            handleStopReadingModule();
        }
        setSelectedModule(module);
    };

    const sortResults = (results: ModuleInterface[]) => {
        switch (
            sortByRef.current // assuming sortByRef is updated to track current sorting option
        ) {
            case "numAsc":
                return [...results].sort(
                    (a, b) => Number(a.module_number) - Number(b.module_number)
                );
            case "numDesc":
                return [...results].sort(
                    (a, b) => Number(b.module_number) - Number(a.module_number)
                );
            case "alphAsc":
                return [...results].sort((a, b) => a.title.localeCompare(b.title));
            case "alphDesc":
                return [...results].sort((a, b) => b.title.localeCompare(a.title));
            case "timeShort":
                return [...results].sort((a, b) => a.duration - b.duration);
            case "timeLong":
                return [...results].sort((a, b) => b.duration - a.duration);
            default:
                return results;
        }
    };

    const applySearchFilters = () => {
        let results = modules.filter((module) => {
            const moduleStatus = module.user_module[0]?.status || "not started";
            return (
                (queryRef.current === "" ||
                    module.title.toLowerCase().includes(queryRef.current.toLowerCase()) ||
                    module.overview.toLowerCase().includes(queryRef.current.toLowerCase())) &&
                (filterTypeRef.current === "" || module.type === filterTypeRef.current) &&
                (filterStatusRef.current === "" || moduleStatus === filterStatusRef.current)
            );
        });
        results = sortResults(results);
        setSearchResults(results);
    };

    const debouncedQuery = useCallback(
        delayFunction(() => applySearchFilters(), 200),
        [modules]
    );

    const handleSearchQuery = (query: string) => {
        setQuery(query);
        queryRef.current = query;
        debouncedQuery();
    };

    const clearSearchParameters = () => {
        handleSearchQuery("");
        setSortBy("");
        setFilterType("");
        setFilterStatus("");
    };

    const handleClickScrollToTopButton = (behavior: ScrollBehavior = "smooth") => {
        if (!selectedModule) {
            moduleListViewerRef.current?.scrollTo({ top: 0, behavior });
            return;
        }
        singleModuleViewerRef.current?.scrollTo({ top: 0, behavior });
    };

    useEffect(() => {
        sortByRef.current = sortBy;
        filterTypeRef.current = filterType;
        filterStatusRef.current = filterStatus;
        applySearchFilters();
    }, [sortBy, filterType, filterStatus, modules]);

    useEffect(() => {
        if (!selectedModule) {
            const moduleListViewer = document.querySelector(".moduleSearchAndResultsContainer");
            if (moduleListViewer) {
                moduleListViewer.scrollTop = scrollPosition;
            }
        }
    }, [selectedModule]);

    // setup event listener for scroll to show/hide scroll up button
    useEffect(() => {
        const handleScroll = debounce(() => {
            const moduleListViewer = moduleListViewerRef.current;
            const singleModuleViewer = singleModuleViewerRef.current;

            if (moduleListViewer && moduleListViewer.scrollTop > 300) {
                setShowScrollUpButton(true);
            } else if (singleModuleViewer && singleModuleViewer.scrollTop > 300) {
                setShowScrollUpButton(true);
            } else {
                setShowScrollUpButton(false);
            }
        }, 200);

        if (moduleListViewerRef.current) {
            moduleListViewerRef.current.addEventListener("scroll", handleScroll);
        }

        if (singleModuleViewerRef.current) {
            singleModuleViewerRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            moduleListViewerRef.current?.removeEventListener("scroll", handleScroll);
            singleModuleViewerRef.current?.removeEventListener("scroll", handleScroll);
        };
    }, [showModuleViewer, selectedModule]);

    // Sizing the drawer to match editor width
    useEffect(() => {
        const moduleViewerOverallContainer = document.querySelectorAll(
            ".moduleViewerOverallContainer"
        );
        if (moduleViewerOverallContainer) {
            preventZoomOnScrollableElements(moduleViewerOverallContainer);
        }

        const leftObserver = new ResizeObserver(updateWidths);
        const rightObserver = new ResizeObserver(updateWidths);

        if (leftColumnRef.current) {
            leftObserver.observe(leftColumnRef.current);
        }

        if (rightColumnRef.current) {
            rightObserver.observe(rightColumnRef.current);
        }

        return () => {
            if (leftColumnRef.current) {
                leftObserver.disconnect();
            }
            if (rightColumnRef.current) {
                rightObserver.disconnect();
            }
        };
    }, []);

    // Fetch modules
    useEffect(() => {
        // Get modules
        const fetchModules = async () => {
            try {
                const response = await axiosInstance.get("/api/modules/get-modules");
                const data: ModuleInterface[] = response.data;
                const formattedData = data.map((module) => {
                    return {
                        ...module,
                        category: capitalizeWords(module.category),
                        title: capitalizeWords(module.title),
                    };
                });
                setModules(formattedData);
            } catch (e) {}
        };
        fetchModules();
    }, []);

    return (
        <div className="moduleViewerOverallContainer">
            <Button ref={openModuleViewerButtonRef} onClick={openModuleViewer}>
                View Modules
            </Button>
            <Drawer
                placement="bottom"
                classNames={{ body: "chatbotBody" }}
                styles={{
                    header: {
                        display: "none",
                    },
                    wrapper: {
                        left: `${leftPosition}px`,
                        right: `${rightPosition}px`,
                        transition: isDragging ? "none" : "",
                    },
                }}
                height={drawerHeight}
                open={showModuleViewer}
                onClose={closeModuleViewer}
                className={isDragging ? "chatbotDrawerDragging" : ""}
            >
                <div className="moduleViewerContent">
                    <div
                        className="resizeBar"
                        title="Drag to resize"
                        onMouseDown={onMouseDown}
                    ></div>
                    <div
                        className={`moduleViewerHeader${
                            selectedModule ? " singleModuleHeader" : ""
                        }`}
                    >
                        <div className="moduleHeaderButtonContainer">
                            <Button className="closeModuleViewerButton" onClick={closeModuleViewer}>
                                Close Module Viewer
                            </Button>
                            {selectedModule && (
                                <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
                                    <Button onClick={() => handleSelectModule(null)}>
                                        <ArrowLeftOutlined />
                                        Back
                                    </Button>
                                </ConfigProvider>
                            )}
                        </div>
                        <h2 className="moduleViewerTitle">
                            {selectedModule
                                ? `${selectedModule.module_number} - ${selectedModule.title}`
                                : "All Learning Modules"}
                        </h2>
                        {!selectedModule && <div className="moduleViewerHeaderSpacer"></div>}
                    </div>

                    {selectedModule ? (
                        <div ref={singleModuleViewerRef} className="individualModuleContainer">
                            <div
                                className="moduleViewerModuleContent"
                                onCopy={() =>
                                    handleCopyEvent("learning module", {
                                        module_title: `${selectedModule.module_number} - ${selectedModule.title}`,
                                    })
                                }
                            >
                                <MarkdownRenderer content={selectedModule.content} />
                                <Button
                                    className={
                                        "markModuleCompleteButton" +
                                        `${
                                            selectedModule.user_module[0]?.is_completed
                                                ? " completeModule"
                                                : ""
                                        }`
                                    }
                                    disabled={selectedModule.user_module[0]?.is_completed}
                                    onClick={handleMarkModuleAsComplete}
                                >
                                    {selectedModule.user_module[0]?.is_completed
                                        ? "Completed"
                                        : "Mark as complete"}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div ref={moduleListViewerRef} className="moduleSearchAndResultsContainer">
                            <Form layout="vertical" className="moduleViewerForm">
                                <Form.Item>
                                    <Space.Compact className="searchBarAndClearButtonContainer">
                                        <Input
                                            placeholder="Search title..."
                                            value={query}
                                            onChange={(e) => handleSearchQuery(e.target.value)}
                                            name="moduleSearch"
                                        />
                                        <Button
                                            onClick={clearSearchParameters}
                                            name="clearModuleSearch"
                                        >
                                            Clear search
                                        </Button>
                                    </Space.Compact>
                                </Form.Item>
                                <div className="sortByContainer">
                                    <label htmlFor="sortBySelect">Sort by:</label>
                                    <Select
                                        id="sortBySelect"
                                        value={sortBy}
                                        onChange={(value) => setSortBy(value)}
                                    >
                                        <Option value="">Module Number (asc)</Option>
                                        <Option value="numDesc">Module Number (desc)</Option>
                                        <Option value="alphAsc">Alphabetical (asc)</Option>
                                        <Option value="alphDesc">Alphabetical (desc)</Option>
                                        <Option value="timeShort">Shortest</Option>
                                        <Option value="timeLong">Longest</Option>
                                    </Select>
                                </div>
                                <Form.Item>
                                    <label>
                                        Type{" "}
                                        <RadioGroup
                                            className="moduleSearchRadioGroup"
                                            name="moduleType"
                                            value={filterType}
                                            onChange={(e) => setFilterType(e.target.value)}
                                        >
                                            <Radio value="">All</Radio>
                                            <Radio value="code">Code</Radio>
                                            <Radio value="concept">Concept</Radio>
                                        </RadioGroup>
                                    </label>
                                </Form.Item>
                                <Form.Item>
                                    <label>
                                        Status
                                        <RadioGroup
                                            className="moduleSearchRadioGroup"
                                            name="moduleStatus"
                                            value={filterStatus}
                                            onChange={(e) => setFilterStatus(e.target.value)}
                                        >
                                            <Radio value="">All</Radio>
                                            <Radio value="not started">Not started</Radio>
                                            <Radio value="in-progress">In progress</Radio>
                                            <Radio value="completed">Complete</Radio>
                                        </RadioGroup>
                                    </label>
                                </Form.Item>
                            </Form>

                            <ConfigProvider
                                theme={{
                                    algorithm: theme.darkAlgorithm,
                                }}
                            >
                                <div
                                    className="moduleSearchResultsContainer"
                                    onCopy={() => handleCopyEvent("learning modules list")}
                                >
                                    <List
                                        className="moduleListContainer"
                                        itemLayout="vertical"
                                        size="large"
                                    >
                                        {searchResults
                                            .slice(0, isDragging ? 8 : undefined)
                                            .map((module) => {
                                                const moduleStatusClass =
                                                    moduleStatusToClass[
                                                        module.user_module[0]?.status
                                                    ] || "";
                                                const moduleStatusText = capitalizeWords(
                                                    module.user_module[0]?.status || "not started"
                                                );
                                                return (
                                                    <List.Item
                                                        className="moduleListItem"
                                                        key={module.id}
                                                    >
                                                        <div className="moduleListItemTop">
                                                            <div className="moduleTitleAndDescription">
                                                                <h4 className="moduleListItemTitle">
                                                                    <Text>
                                                                        {`${module.module_number} - ${module.title}`}
                                                                    </Text>
                                                                </h4>
                                                                <Space
                                                                    className="moduleDetails"
                                                                    align="start"
                                                                >
                                                                    <div
                                                                        className={`moduleStatusContainer ${moduleStatusClass}`}
                                                                        title={
                                                                            module.user_module[0]
                                                                                ?.is_completed
                                                                                ? `Completed ${convertToUserLocalTime(
                                                                                      module
                                                                                          .user_module[0]
                                                                                          ?.date_completed
                                                                                  )}`
                                                                                : moduleStatusText
                                                                        }
                                                                    >
                                                                        {moduleStatusText}
                                                                    </div>
                                                                    <Text>
                                                                        <ClockCircleOutlined />{" "}
                                                                        {module.duration}{" "}
                                                                        {`${
                                                                            module.duration > 1
                                                                                ? "minutes"
                                                                                : "minute"
                                                                        }`}
                                                                    </Text>
                                                                    <Text>
                                                                        {module.type === "code" ? (
                                                                            <CodeOutlined />
                                                                        ) : (
                                                                            <BulbOutlined />
                                                                        )}{" "}
                                                                        {module.type}
                                                                    </Text>
                                                                </Space>
                                                            </div>
                                                            <Button
                                                                onClick={() =>
                                                                    handleSelectModule(module)
                                                                }
                                                                aria-label={`View ${module.title} module`}
                                                            >
                                                                View Module
                                                            </Button>
                                                        </div>
                                                        <div className="moduleOverview">
                                                            {module.overview}
                                                        </div>
                                                    </List.Item>
                                                );
                                            })}
                                        {searchResults.length === 0 && (
                                            <div className="noResultsContainer">
                                                <Text>No results found</Text>
                                            </div>
                                        )}
                                    </List>
                                </div>
                            </ConfigProvider>
                        </div>
                    )}
                </div>
                {showScrollUpButton && (
                    <Button
                        title="Scroll to top"
                        className={`backToTopButton`}
                        icon={<VerticalAlignTopOutlined />}
                        onClick={() => handleClickScrollToTopButton()}
                    ></Button>
                )}
            </Drawer>
        </div>
    );
};

export default ModuleViewer;
