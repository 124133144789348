import {
    DataOutput,
    ModelNode,
    ModelType,
    PreviousNodeData,
} from "components/ReteInterfaces/SharedInterfaces";
import { PackageImporter } from "../components/ReteNodes/PackageImporter";
import { debouncedAutoSave, globalEditor } from "../components/editor";
import { ClassicPreset } from "rete";
import { LinearRegressionModel } from "components/ReteNodes/Models/LinearRegressionModel";
import axios from "axios";
import { BACKEND_URL } from "../config";
import React from "react";

export const preventZoomOnScrollableElements = (elements: NodeListOf<Element>) => {
    for (const e of elements) {
        e.addEventListener(
            "wheel",
            (e: Event) => {
                e.stopPropagation();
            },
            { passive: true }
        );
    }
};

export const getPreviousNodes = (inputs: DataOutput[]) => {
    const previousNodes: PreviousNodeData[] = [];
    inputs.forEach((input) => {
        previousNodes.push(...input.previousNodes);
    });
    return previousNodes;
};

export const delayMs = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export function arraysEqual(a: string[], b: string[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export function isEmpty(arr: any[]) {
    return arr.length === 0;
}

export const delayFunction = <F extends (...args: any[]) => void>(
    fn: F,
    ms: number
): ((...args: Parameters<F>) => void) => {
    let timer: NodeJS.Timeout | null = null;

    return function (...args: Parameters<F>) {
        clearTimeout(timer as NodeJS.Timeout);
        timer = setTimeout(() => {
            fn(...(args as Parameters<F>));
        }, ms);
    };
};

export const loadCollectedImportStatements = (previousNodes: PreviousNodeData[]) => {
    const packageImporter: PackageImporter | undefined = previousNodes.find(
        (node): node is PackageImporter & PreviousNodeData => {
            return node.label === "Import Libraries";
        }
    );
    const importStatement = packageImporter?.ImportManager.combineImportStatements() || "";
    return importStatement;
};

export const getConnectionNodeData = (connection: ClassicPreset.Connection<any, any>) => {
    const source = globalEditor.getNodes().find((node) => node.id === connection.source);
    const target = globalEditor.getNodes().find((node) => node.id === connection.target);
    return { source, target };
};

export const mergeCommonAncestorCodePaths = (
    path1: string[],
    path2: string[],
    importStatement: string
) => {
    const minLength = Math.min(path1.length, path2.length);
    let i = 1;
    while (i < minLength && path1[i] === path2[i]) {
        i++;
    }
    const commonAncestorPath = [importStatement, ...path1.slice(1, i)];
    const path1Relative = path1.slice(i);
    const path2Relative = path2.slice(i);
    return [...commonAncestorPath, ...path1Relative, ...path2Relative];
};

export const mergeCommonAncestorNodePaths = (
    path1: PreviousNodeData[],
    path2: PreviousNodeData[]
) => {
    const minLength = Math.min(path1.length, path2.length);
    let i = 0;
    while (i < minLength && path1[i].label === path2[i].label) {
        i++;
    }
    const commonAncestorPath = path1.slice(0, i);
    const path1Relative = path1.slice(i);
    const path2Relative = path2.slice(i);

    return [...commonAncestorPath, ...path1Relative, ...path2Relative];
};

export const findModelType = (previousNodes: PreviousNodeData[]) => {
    const model = previousNodes.find((node) => "model" in node) as ModelNode | undefined;
    const modelType: ModelType | null = model?.model || null;
    return modelType;
};

export const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    timeout: 10000,
    withCredentials: true,
});

type KeyTranslations = {
    [key: string]: string;
};

const keyTranslations: KeyTranslations = {
    "currentURL": "Current URL",
    "url_download": "Allow URL Download",
    "uploadedDataset": "Uploaded Dataset File",
    "currentImports": "Current Imports",
    "selectedOption": "Selected Option",
    "currentCode": "Current Code",
    "currentConsole": "Current Console Output",
    "dataframeColumns": "Dataframe Columns",
    "selectedFeatures": "Selected Features",
    "targetVariable": "Target Variable",
    "testSize": "Test Size",
    "l1_ratio": "L1 Ratio",
    "max_iterations": "Maximum Iterations",
    "penalty_type": "Penalty Type",
    "solver_type": "Solver Type",
    "regularization_strength": "Regularization Strength",
    "fit_intercept": "Fit Intercept",
    "force_positive_coefficients": "Force Positive Coefficients",
    "copy_X": "Copy X",
    "plotType": "Plot Type",
    "categoricalColumn": "Categorical Column",
    "numericalColumns": "Numerical Columns",
    "bins": "Bins",
};

export const translateKeys = (data: any) => {
    return Object.keys(data).reduce((newData: KeyTranslations, key) => {
        const translatedKey = keyTranslations[key] || key; // Use translation or default to the original key
        newData[translatedKey] = data[key];
        return newData;
    }, {});
};

export const getControlDataForNode = (nodeType: string, relevantData: any) => {
    switch (nodeType) {
        case "DatasetImporter":
            const { value, useUploadedDataset, uploadedDataset } = relevantData.data;
            return {
                currentURL: value,
                url_download: !useUploadedDataset,
                uploadedDataset: uploadedDataset.fileName,
            };
        case "PackageImporter":
            const { selectedItems } = relevantData.data;
            return { currentImports: selectedItems };
        case "DataframeLoader":
            return "";
        case "DataframeInspector":
            const { option } = relevantData.data;
            return { selectedOption: option };
        case "CodeViewer": {
            const { controls } = relevantData.data;
            return { currentCode: controls.display.currentCode };
        }
        case "ConsoleViewer": {
            const { controls } = relevantData.data;
            return { currentConsole: controls.display.currentConsole };
        }
        case "TestTrainSplit":
            const { dataframeColumns, selectedFeatures, targetVariable, testSize } =
                relevantData.data;
            return { dataframeColumns, selectedFeatures, targetVariable, testSize };
        case "TrainModel":
            return "";
        case "LogisticRegressionModel":
            const { l1_ratio, max_iter, penalty, solver, C } = relevantData.data;
            return {
                l1_ratio,
                max_iterations: max_iter,
                penalty_type: penalty,
                solver_type: solver,
                regularization_strength: C,
            };
        case "LinearRegressionModel":
            const { fitIntercept, positive, copyX } = relevantData.data;
            return {
                fit_intercept: fitIntercept,
                force_positive_coefficients: positive,
                copy_X: copyX,
            };
        case "TrainModel":
            return "";
        case "ScoreModelBasic":
            return "";
        case "Visualizer":
            const { plotType, categoricalColumn, numericalColumns, bins } = relevantData.data;
            switch (plotType) {
                case "scatter":
                    return { plotType, categoricalColumn, numericalColumns };
                case "histogram":
                    return { plotType, numericalColumns, bins };
                case "heatmap":
                    return { plotType };
                case "bar_chart":
                    return { plotType, categoricalColumn, numericalColumns };
                default:
                    return { plotType: "No plot selected" };
            }
        default:
            return null;
    }
};

export const getRelevantEventData = (type: string, relevantData: any) => {
    switch (type) {
        // GlOBAL EVENTS
        case "copy_detected":
            return { ...relevantData };
        case "paste_detected":
            return { ...relevantData };

        // EDITOR EVENTS
        case "noderemoved":
        case "nodecreated": {
            const nodeType = relevantData.data.getType();
            const controlData = getControlDataForNode(nodeType, relevantData);
            return {
                blockType: nodeType,
                blockData: controlData,
                source: relevantData.source,
            };
        }
        case "nodetranslated":
            const node = globalEditor.getNode(relevantData.data.id);
            const nodeType = node?.getType();
            const controlData = getControlDataForNode(nodeType, { data: node });
            return {
                blockType: nodeType,
                blockData: controlData,
            };
        case "connectioncreated":
        case "connectionremoved":
            const source = globalEditor.getNode(relevantData.data.source);
            const sourceControlData = getControlDataForNode(source.getType(), {
                data: source,
            });
            const target = globalEditor.getNode(relevantData.data.target);
            const targetControlData = getControlDataForNode(target.getType(), {
                data: target,
            });
            return {
                source: { blockType: source.getType(), blockData: sourceControlData },
                target: { blockType: target.getType(), blockData: targetControlData },
            };

        // EDITOR CONTROLS
        case "manual_save":
        case "zoom_to_fit":
        case "auto_layout":
        case "run_program":
        case "open_image_gallery":
        case "close_image_gallery":
        case "delete_image":
            return { ...relevantData };

        // PROBLEM
        case "start_problem":
        case "submit_problem":
        case "start_new_submission":
        case "view_latest_submission":
        case "view_past_submission":
            return { ...relevantData };

        // NOTES
        case "save_note":
        case "update_note":
        case "delete_note":
            return { ...relevantData };

        // AI CHATBOT
        case "open_chatbot":
        case "close_chatbot":
        case "chatbot_request":
        case "create_new_conversation":
        case "open_problem_description":
        case "close_problem_description":
            return { ...relevantData };

        // MODULE VIEWER
        case "open_module_viewer":
        case "close_module_viewer":
        case "start_reading_module":
        case "stop_reading_module":
        case "mark_module_as_complete":
            return { ...relevantData };

        // PACKAGE IMPORTER
        case "import_library":
        case "remove_library_import":
            return { ...relevantData, blockType: "PackageImporter" };

        // DATASET IMPORTER
        case "upload_local_dataset":
        case "remove_local_dataset":
        case "toggle_url_download_switch":
        case "enter_url_download":
        case "preview_dataset":
            return { ...relevantData, blockType: "DatasetImporter" };

        // TEST TRAIN SPLIT
        case "select_feature":
        case "deselect_feature":
        case "set_target_variable":
        case "set_test_size_ratio":
            return { ...relevantData, blockType: "TestTrainSplit" };

        // DATAFRAME INSPECTOR
        case "set_dataframe_inspection_option":
            return { ...relevantData, blockType: "DataframeInspector" };

        // LOGISTIC REGRESSION MODEL
        case "set_regularization_strength":
        case "set_max_iterations":
        case "set_solver_type":
        case "set_penalty_type":
        case "set_l1_ratio":
            return { ...relevantData, blockType: "LogisticRegressionModel" };

        // LINEAR REGRESSION MODEL
        case "set_fit_intercept":
        case "set_force_positive_coefficients":
        case "set_copy_x":
            return { ...relevantData, blockType: "LinearRegressionModel" };

        // VISUALIZER
        case "set_plot_type":
        case "set_categorical_column":
        case "set_numerical_column":
        case "set_bins":
        case "click_view_plots":
            return { ...relevantData, blockType: "Visualizer" };

        // VISUALIZER MODAL
        case "save_plot_image":
            return { ...relevantData, blockType: "VisualizerModal" };

        default:
            return null;
    }
};

export const getDetailsFromAction = (action: string, eventData: any) => {
    switch (action) {
        // GlOBAL EVENTS
        case "copy_detected":
            return `Copied content from ${eventData.origin}`;
        case "paste_detected":
            return `Pasted copied content into ${eventData.destination}`;

        // EDITOR EVENTS
        case "noderemoved":
            return `Deleted node ${eventData.blockType}`;
        case "nodecreated":
            return `Created node ${eventData.blockType} by ${eventData.source}`;
        case "nodetranslated":
            return `Repositioned node ${eventData.blockType}`;
        case "connectioncreated":
            return `New connection from ${eventData.source.blockType} to ${eventData.target.blockType}`;
        case "connectionremoved":
            return `Removed connection from ${eventData.source.blockType} to ${eventData.target.blockType}`;

        // PROBLEM
        case "start_problem":
            return `Started first attempt for problem ${eventData.problem_number}`;
        case "submit_problem":
            return `Submitted problem ${eventData.problem_number}, attempt #${eventData.submission_number}`;
        case "start_new_submission":
            return `Starting submission number ${eventData.submission_number} for problem ${eventData.problem_number}`;
        case "view_latest_submission":
            return `Viewed latest submission (${eventData.submission_number}) for problem ${eventData.problem_number}`;
        case "view_past_submission":
            return `Viewed past submission (${eventData.submission_number}) for problem ${eventData.problem_number}`;

        // NOTES
        case "save_note":
            return `Saved new note with ID ${eventData.note_id}${
                eventData.title ? ` and title '${eventData.title}'` : ""
            }`;
        case "update_note":
            return `Updated note with ID ${eventData.note_id}`;
        case "delete_note":
            return `Deleted note with ID ${eventData.note_id}`;

        // AI CHATBOT
        case "open_chatbot":
            return `Opened the AI chatbot`;
        case "close_chatbot":
            return `Closed the AI chatbot`;
        case "chatbot_request":
            return `Chatbot request: ${eventData.request}`;
        case "create_new_conversation":
            return `Created a conversation with the 'New Chat' button`;
        case "open_problem_description":
            return `Opened the problem description drawer`;
        case "close_problem_description":
            return `Closed the problem description drawer`;

        // MODULE VIEWER
        case "open_module_viewer":
            return `Opened the module viewer`;
        case "close_module_viewer":
            return `Closed the module viewer`;
        case "start_reading_module":
            return `Started reading module ${eventData.module_number}: ${eventData.title}`;
        case "stop_reading_module":
            return `Stopped reading module ${eventData.module_number}: ${eventData.title}`;
        case "mark_module_as_complete":
            return `Marked module ${eventData.module_number}: ${eventData.title} as complete`;

        // EDITOR CONTROLS
        case "manual_save":
            return `Clicked the editor save button`;
        case "zoom_to_fit":
            return `Clicked the zoom-to-fit button`;
        case "auto_layout":
            return "Clicked the auto-layout button";
        case "noderesized":
            return "Currently side effect from auto_layout";
        case "run_program":
            return "Clicked the main run program button";
        case "open_image_gallery":
            return "Opened the image gallery modal";
        case "close_image_gallery":
            return "Closed the image gallery modal";
        case "delete_image":
            return `Deleted image ID ${eventData.deleted_image_id}`;

        // PACKAGE IMPORTER
        case "import_library":
            return `Imported library '${eventData.libraryName}'`;
        case "remove_library_import":
            return `Removed library '${eventData.libraryName}'`;

        // DATASET IMPORTER
        case "upload_local_dataset":
            return `Uploaded ${eventData.fileName}`;
        case "remove_local_dataset":
            return `Removed ${eventData.fileName}`;
        case "toggle_url_download_switch":
            return `Toggled URL download switch to ${eventData.url_download}`;
        case "enter_url_download":
            return `Entered URL ${eventData.currentURL}`;
        case "preview_dataset":
            return `Previewed dataset`;

        // TEST TRAIN SPLIT
        case "select_feature":
            return `Selected feature '${eventData.featureName}'`;
        case "deselect_feature":
            return `Deselected feature '${eventData.featureName}'`;
        case "set_target_variable":
            return `Set target variable to '${eventData.targetVariable}'`;
        case "set_test_size_ratio":
            return `Set test size ratio to '${eventData.testSize}'`;

        // DATAFRAME INSPECTOR
        case "set_dataframe_inspection_option":
            return `Set dataframe inspection option to '${eventData.inspection_option}'`;

        // LOGISTIC REGRESSION MODEL
        case "set_l1_ratio":
            return `Set L1 ratio to '${eventData.l1_ratio}'`;
        case "set_max_iterations":
            return `Set max iterations to '${eventData.max_iterations}'`;
        case "set_regularization_strength":
            return `Set regularization strength to '${eventData.regularization_strength}'`;
        case "set_solver_type":
            return `Set solver type to '${eventData.solver_type}'`;
        case "set_penalty_type":
            return `Set penalty type to '${eventData.penalty_type}'`;

        // LINEAR REGRESSION MODEL
        case "set_fit_intercept":
            return `Set fit intercept to '${eventData.fit_intercept}'`;
        case "set_force_positive_coefficients":
            return `Set force positive coefficients to '${eventData.force_positive_coefficients}'`;
        case "set_copy_x":
            return `Set copy x to '${eventData.copy_X}'`;

        // VISUALIZER
        case "set_plot_type":
            return `Set plot type to '${eventData.plotType}'`;
        case "set_categorical_column":
            return `Set categorical column to '${eventData.categorical_column}' for plot type '${eventData.plotType}'`;
        case "set_numerical_column":
            return `Add numerical column '${eventData.new_column}' for plot type '${eventData.plotType}'`;
        case "set_bins":
            return `Set bins to '${eventData.bins}' for plot type '${eventData.plotType}'`;
        case "click_view_plots":
            return `Viewed plots (if any) for plot type '${eventData.plotType}'`;

        // VISUALIZER MODAL
        case "save_plot_image":
            return `Saved plot image titled '${eventData.imageTitle}'`;

        default:
            return null;
    }
};

export interface UserLog {
    details?: string | null;
    problem_id?: number;
    eventData?: any;
    action: string;
}

export const saveUserLog = async (log: UserLog) => {
    try {
        const res = await axiosInstance.post("/api/logs/user-action", log, {
            withCredentials: true,
        });
    } catch (error) {
        console.error(error);
    }
};

export const handleCopyEvent = (origin: string, relevantData?: any) => {
    const selection = window.getSelection();
    if (selection) {
        const selectedText = selection.toString();
        if (selectedText) {
            debouncedAutoSave(
                "copy_detected",
                { content: selectedText, origin, ...relevantData },
                true
            );
        }
    }
};

export const handlePasteEvent = (
    destination: string,
    e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
    const pasted_content = e.clipboardData.getData("text");
    if (pasted_content) {
        debouncedAutoSave("paste_detected", { destination, pasted_content }, true);
    }
};
