import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/ProblemDescription.css";
import { Button, ConfigProvider, theme } from "antd";
import PageLoadingMessage from "./TextComponents/PageLoadingMessage";
import ProblemContext from "../contexts/ProblemContext";
import { handleCopyEvent } from "../helpers/nodeHelpers";

interface ProblemDescriptionProps {
    inDrawer: boolean;
    isAdmin: boolean;
}

const ProblemDescription = ({ inDrawer = false, isAdmin }: ProblemDescriptionProps) => {
    const navigate = useNavigate();
    const problemContext = useContext(ProblemContext);
    if (!problemContext) {
        return <PageLoadingMessage />;
    }
    const { problem, setProblem } = problemContext;

    const handleOpenProblem = () => {
        if (problem?.problem_status === "not started") {
            setProblem((prev) => {
                if (prev) {
                    return { ...prev, problem_status: "in-progress" };
                }
                return prev;
            });
        }

        if (problem?.problem_status === "completed") {
            navigate(`/problems/${problem?.id}/submission`);
            return;
        } else {
            navigate(`/problems/${problem?.id}`);
        }
    };

    useEffect(() => {
        if (problem?.adminOnly && !isAdmin) {
            navigate("/problems");
        }
    }, [problem]);

    return (
        <div
            className="mainContainer problemDescriptionContainer"
            onCopy={() =>
                handleCopyEvent(
                    inDrawer ? "problem description drawer" : "problem description page"
                )
            }
        >
            {!inDrawer && (
                <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
                    <Button
                        size="large"
                        className="backToProblemsButton"
                        onClick={() => navigate("/problems")}
                    >
                        {"< Back to Problems"}
                    </Button>
                </ConfigProvider>
            )}

            <div className="titleContainer">
                <h1>{`Problem ${problem?.problem_number || 1} - ${problem?.title}`}</h1>
            </div>

            <div
                className="descriptionContainer"
                dangerouslySetInnerHTML={{ __html: problem?.description || "Loading..." }}
            ></div>

            {!inDrawer && (
                <div className="buttonContainer">
                    <Button className="startButton" size="large" onClick={handleOpenProblem}>
                        {problem?.problem_status === "not started" && "Start Problem"}
                        {problem?.problem_status === "in-progress" && "Resume Problem"}
                        {problem?.problem_status === "completed" && "View Submission"}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ProblemDescription;
