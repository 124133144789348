import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import EditorButtonPanel, { handleZoomToFit } from "./EditorButtonPanel";
import ProblemDescriptionDrawer from "./ProblemDescriptionDrawer";
import ProblemContext from "../contexts/ProblemContext";
import PageLoadingMessage from "./TextComponents/PageLoadingMessage";
import { Drag } from "rete-react-plugin";
import { Button, Col, ConfigProvider, InputNumber, Row, Slider } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../helpers/nodeHelpers";
import html2canvas from "html2canvas";
import { Modal } from "antd";
import {
    ExclamationCircleFilled,
    ExclamationCircleOutlined,
    ExclamationCircleTwoTone,
} from "@ant-design/icons";
import { image } from "html2canvas/dist/types/css/types/image";
import { useNavigate } from "react-router-dom";
import { debouncedAutoSave, globalArea, globalEditor, globalSelector } from "./editor";
import { blobToBase64, fetchBlob } from "./VisualizerModal";
import { useSubmission } from "../providers/ProblemSubmissionProvider";
import { getEditorState } from "../helpers/importExportFunctions";

const { confirm } = Modal;

interface EditorOverlayContainerProps {
    runProgram: boolean;
    setRunProgram: Dispatch<SetStateAction<boolean>>;
    setIsImageGalleryOpen: Dispatch<SetStateAction<boolean>>;
    leftColumnRef: React.RefObject<HTMLDivElement>;
    rightColumnRef: React.RefObject<HTMLDivElement>;
    isAdmin: boolean;
    codePreview: string;
    consoleText: string;
    editorRef: React.RefObject<HTMLDivElement>;
    openChatbotButtonRef: React.RefObject<HTMLButtonElement>;
    openModuleViewerButtonRef: React.RefObject<HTMLButtonElement>;
    generatedPlots: string[];
    saveIndicatorMessageRef: React.RefObject<HTMLDivElement>;
}

export interface ProblemInterface {
    id: number;
    title: string;
    problem_number: number;
    problem_status: "not started" | "in-progress" | "completed";
    description: string;
    adminOnly: boolean;
}

const EditorOverlayContainer = ({
    runProgram,
    setRunProgram,
    setIsImageGalleryOpen,
    leftColumnRef,
    rightColumnRef,
    isAdmin,
    codePreview,
    consoleText,
    editorRef,
    openChatbotButtonRef,
    openModuleViewerButtonRef,
    generatedPlots,
    saveIndicatorMessageRef,
}: EditorOverlayContainerProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [localRunProgram, setLocalRunProgram] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [confidence, setConfidence] = useState<number>(50);
    const [editorImageURL, setEditorImageURL] = useState<string>("");
    const navigate = useNavigate();
    const problemContext = useContext(ProblemContext);
    if (!problemContext) {
        return <PageLoadingMessage />;
    }
    const { problem, setProblem } = problemContext;

    const triggerSubmitProblem = async () => {
        await handleZoomToFit();
        setIsSubmitting(true);

        const editorImageURL = await captureEditorSnapshot();
        setEditorImageURL(editorImageURL);

        setLocalRunProgram(true);
    };

    const submitProblem = async () => {
        const editor_state = getEditorState();
        try {
            const res = await axiosInstance.post(
                "/api/problems/submit-problem",
                {
                    problem_id: problem?.id,
                    full_code: codePreview,
                    console_output: consoleText,
                    image_data_url: editorImageURL,
                    confidence,
                    editor_state,
                },
                { withCredentials: true }
            );
            if (!res.data.success) {
                console.log("Error submitting problem.");
                throw new Error("Error submitting problem.");
            }
            setProblem((prevProblem) => {
                if (prevProblem) {
                    return { ...prevProblem, problem_status: "completed" };
                }
                return prevProblem;
            });
            const submissionData = {
                ...res.data.problem_submission_data,
                "date_started": new Date(
                    res.data.problem_submission_data.date_started
                ).toLocaleString(),
                "date_completed": new Date(
                    res.data.problem_submission_data.date_completed
                ).toLocaleString(),
            };
            debouncedAutoSave("submit_problem", submissionData, true);
            if (generatedPlots.length > 0) {
                const uploadPromises = generatedPlots.map(async (plot, index) => {
                    const blob = await fetchBlob(plot);
                    const base64 = await blobToBase64(blob);
                    return await axiosInstance.post(
                        "/api/images/save-generated-submission-image",
                        {
                            image: base64,
                            imageTitle: `Generated Plot ${index + 1}`,
                            problem_submission_id: submissionData.problem_submission_id,
                        },
                        { withCredentials: true }
                    );
                });
                await Promise.all(uploadPromises);
            }
            navigate(`/problems/${problem?.id}/submission`);
        } catch (e: any) {
            console.error(e.response.data.message);
        }
        setIsSubmitting(false);
    };

    const confirmSubmitProblem = () => {
        setIsConfirmModalOpen(true);
    };

    const updateConfidence = (value: number | undefined | null) => {
        setConfidence(value || 0);
    };

    const captureEditorSnapshot = async () => {
        if (editorRef.current) {
            const dpr = window.devicePixelRatio || 5;
            openChatbotButtonRef.current?.style.setProperty("z-index", "-1");
            openModuleViewerButtonRef.current?.style.setProperty("z-index", "-1");
            saveIndicatorMessageRef.current?.style.setProperty("z-index", "-1");

            globalSelector.unselectAll();

            await new Promise((resolve) => requestAnimationFrame(resolve));

            try {
                const canvas = await html2canvas(document.getElementById("root")!, {
                    useCORS: true,
                    scale: dpr,
                    foreignObjectRendering: true,
                });
                openChatbotButtonRef.current?.style.setProperty("z-index", "auto");
                openModuleViewerButtonRef.current?.style.setProperty("z-index", "auto");
                saveIndicatorMessageRef.current?.style.setProperty("z-index", "auto");
                const rect = editorRef.current.getBoundingClientRect();

                const croppedCanvas = document.createElement("canvas");
                croppedCanvas.width = rect.width * dpr;
                croppedCanvas.height = rect.height * dpr;
                const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
                const scrollTop = window.scrollY || document.documentElement.scrollTop;
                const ctx = croppedCanvas.getContext("2d");

                // Draw the cropped image onto the new canvas
                ctx?.drawImage(
                    canvas,
                    (rect.left + scrollLeft) * dpr,
                    (rect.top + scrollTop) * dpr,
                    rect.width * dpr,
                    rect.height * dpr,
                    0,
                    0,
                    rect.width * dpr,
                    rect.height * dpr
                );

                const imageURL = croppedCanvas.toDataURL("image/png");
                return imageURL;
            } catch (e) {
                console.error("Error capturing image:", e);
                openChatbotButtonRef.current?.style.setProperty("visibility", "visible");
                openModuleViewerButtonRef.current?.style.setProperty("visibility", "visible");
            }
        }
        return "";
    };

    useEffect(() => {
        if (localRunProgram) {
            setRunProgram(true);
        } else if (isSubmitting && !localRunProgram) {
            submitProblem();
        }
    }, [localRunProgram]);

    useEffect(() => {
        if (!runProgram) {
            setLocalRunProgram(false);
        }
    }, [runProgram]);

    return (
        <div className="editorOverlayContainer">
            <Drag.NoDrag>
                <div className="overlayCenteringContainer">
                    <h1 className="editorProblemTitle">
                        {problem
                            ? `Problem ${problem.problem_number} - ${problem.title}`
                            : "Loading..."}
                    </h1>
                    {problem && (
                        <ProblemDescriptionDrawer
                            leftColumnRef={leftColumnRef}
                            rightColumnRef={rightColumnRef}
                            isAdmin={isAdmin}
                        />
                    )}

                    <EditorButtonPanel
                        runProgram={runProgram}
                        setRunProgram={setRunProgram}
                        setIsImageGalleryOpen={setIsImageGalleryOpen}
                        problem_id={problem?.id || 0}
                        consoleText={consoleText}
                        codePreview={codePreview}
                    />
                </div>
                <ConfigProvider
                    theme={{
                        components: {
                            Button: {
                                defaultBg: "#00c800",
                                linkHoverBg: "green",
                                defaultColor: "white",
                                borderColorDisabled: "white",
                                colorTextDisabled: "white",
                                colorBgContainerDisabled: "lightgreen",
                            },
                        },
                    }}
                >
                    <Button
                        loading={isSubmitting}
                        className="submitProblemBtn"
                        onClick={confirmSubmitProblem}
                        title={
                            problem?.problem_status === "completed"
                                ? "Problem submitted"
                                : "Click to submit problem"
                        }
                        disabled={isSubmitting || problem?.problem_status === "completed"}
                    >
                        {problem?.problem_status === "completed" ? "Submitted" : "Submit Problem"}
                    </Button>
                </ConfigProvider>
                <Modal
                    open={isConfirmModalOpen}
                    width={"fit-content"}
                    okText="Submit"
                    onOk={() => {
                        setIsConfirmModalOpen(false);
                        triggerSubmitProblem();
                    }}
                    okButtonProps={{ title: "Confirm submission" }}
                    onCancel={() => {
                        setIsConfirmModalOpen(false);
                    }}
                    cancelButtonProps={{ title: "Cancel submission" }}
                >
                    <div className="confirmProblemModalContent">
                        <div className="confirmModalWarningContainer">
                            <ExclamationCircleTwoTone
                                className="solidExclamationSymbolConfirm"
                                twoToneColor={"#faad14"}
                            />
                            <div className="confirmModalContent">
                                <h3>Are you sure you want to submit?</h3>
                                <p>You will not be able to edit this problem after submission.</p>
                            </div>
                        </div>
                        <Row>
                            <Col offset={2}>
                                <div className="confidenceContainer">
                                    <p>How confident are you in your solution (0 - 100)</p>
                                    <Row>
                                        <Col offset={2} span={16}>
                                            <Slider
                                                min={0}
                                                max={100}
                                                step={5}
                                                value={confidence}
                                                onChange={updateConfidence}
                                            />
                                        </Col>
                                        <Col offset={1} span={4}>
                                            <InputNumber
                                                value={confidence}
                                                onChange={updateConfidence}
                                                step={5}
                                                min={0}
                                                max={100}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </Drag.NoDrag>
        </div>
    );
};

export default EditorOverlayContainer;
