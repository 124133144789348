import { DataOutput, PreviousNodeData } from "components/ReteInterfaces/SharedInterfaces";
import { DataframeInspectorControl } from "../ReteControls/DataframeInspectorControls";
import { ClassicPreset } from "rete";
import { ProblemContextType } from "../../contexts/ProblemContext";

interface optionToCommand {
    [key: string]: string;
}

export class DataframeInspector extends ClassicPreset.Node<
    { input: ClassicPreset.Socket },
    { output: ClassicPreset.Socket },
    { selector: DataframeInspectorControl }
> {
    width = 200;
    height = 170;
    public option: string = "Select an option";
    public options: string[] = ["Info", "Head", "Tail", "Describe", "Columns", "Shape"];
    private change: () => void;
    private update: (type: "node", node: this) => void;
    #optionToCommand: optionToCommand = {
        Info: "print(df.info())",
        Head: "print(df.head())",
        Tail: "print(df.tail())",
        Describe: "print(df.describe())",
        Columns: "print(df.columns)",
        Shape: "print(df.shape)",
    };
    constructor(
        socket: ClassicPreset.Socket,
        update: (type: "node", asset: DataframeInspector) => void,
        change: () => void,
        context: ProblemContextType
    ) {
        super("Dataframe Inspector");
        this.addInput("input", new ClassicPreset.Input(socket, "Input (dataframe required)"));
        this.addOutput("output", new ClassicPreset.Output(socket, "Output"));
        this.addControl(
            "selector",
            new DataframeInspectorControl(this.option, this.options, this.handleSelect.bind(this))
        );
        this.change = change;
        this.update = update;
    }
    getType() {
        return "DataframeInspector";
    }
    getDisplayName() {
        return "Dataframe Inspector";
    }
    handleSelect(option: string) {
        this.option = option;
        this.controls.selector.setOption(option);
        this.update("node", this);
        this.change();
    }

    data(inputs: { input: DataOutput[] }): { output: DataOutput } {
        const previousCodeRows = inputs.input?.[0].code || [];
        const previousNodes = inputs.input?.[0].previousNodes || [];
        const selectedOptionCode =
            this.#optionToCommand[this.controls.selector.option] ||
            "# Select a dataframe inspector option";
        const newCode: string[] = [...previousCodeRows, selectedOptionCode];
        const currentNode: PreviousNodeData = {
            ...this,
            connectedPort: this.outputs.output!,
        };
        const output: DataOutput = {
            code: newCode,
            previousNodes: [...previousNodes, currentNode],
        };
        return { output };
    }
}
