import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Typography, Input, Button, Tooltip } from "antd";
import {
    CheckOutlined,
    CheckSquareTwoTone,
    CloseOutlined,
    DeleteTwoTone,
    DownOutlined,
    EditOutlined,
    SaveOutlined,
    UpOutlined,
} from "@ant-design/icons";
import { Note } from "./Notes";
import { handlePasteEvent } from "../helpers/nodeHelpers";

const { Text, Title, Paragraph } = Typography;

type Props = {
    note: Note;
    handleNoteUpdate: (value: string, title?: string) => void;
    handleDeleteNote: (e: React.MouseEvent, id: number) => void;
};

const EditableNote = ({ note, handleNoteUpdate, handleDeleteNote }: Props) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(note.content);
    const [titleValue, setTitleValue] = useState(note.title);
    const [collapsed, setCollapsed] = useState(true);
    const [showButton, setShowButton] = useState(false);
    const paragraphRef = useRef<HTMLParagraphElement>(null);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const handleEdit = () => {
        setEditing(true);
        setValue(note.content);
        setTitleValue(note.title);
    };

    const handleSave = () => {
        setEditing(false);
        handleNoteUpdate(value, titleValue);
    };

    const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };

    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitleValue(e.target.value);
    };

    useEffect(() => {
        const previousCollapsedState = collapsed;
        const measureContent = () => {
            if (paragraphRef.current) {
                const fullHeight = paragraphRef.current.scrollHeight; // Full content height
                const lineHeight = parseInt(getComputedStyle(paragraphRef.current).lineHeight, 10);
                const linesToShow = 3;
                const maxHeight = lineHeight * linesToShow;

                setShowButton(fullHeight > maxHeight);
                setCollapsed(previousCollapsedState);
            }
        };
        setCollapsed(false);
        setTimeout(measureContent, 0);
    }, [note.content]);

    return (
        <div className="editableNoteContainer">
            {editing ? (
                <>
                    <Input
                        value={titleValue}
                        onChange={handleTitleChange}
                        placeholder="Add title"
                    />
                    <div className="noteContentAndIconContainer">
                        <Input.TextArea
                            value={value}
                            onChange={handleNoteChange}
                            autoSize={{ minRows: 2, maxRows: 15 }}
                            style={{ whiteSpace: "pre-wrap" }}
                            onPaste={(e) => handlePasteEvent("existing note input", e)}
                        />
                        <div className="noteButtonsContainer">
                            <Tooltip title="Save note" mouseEnterDelay={0.3} mouseLeaveDelay={0}>
                                <Button
                                    icon={<CheckOutlined style={{ color: "green" }} />}
                                    onClick={handleSave}
                                    size="small"
                                />
                            </Tooltip>
                            <Tooltip
                                title="Cancel changes"
                                mouseEnterDelay={0.3}
                                mouseLeaveDelay={0}
                            >
                                <Button
                                    icon={<CloseOutlined style={{ color: "red" }} />}
                                    onClick={() => setEditing(false)}
                                    size="small"
                                />
                            </Tooltip>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {note.title && (
                        <div className="readOnlyTitleContainer">
                            <Title level={4}>{note.title}</Title>
                        </div>
                    )}
                    <div className="noteContentAndIconContainer">
                        <Paragraph
                            className={`readOnlyNoteContent${
                                showButton ? "" : " adjustForCollapseButton"
                            }`}
                            ellipsis={collapsed ? { rows: 3 } : false}
                            ref={paragraphRef}
                        >
                            {note.content}
                        </Paragraph>
                        {showButton && (
                            <span onClick={toggleCollapse} className="expandCollapseNoteButton">
                                {collapsed ? (
                                    <span title="Click to expand">
                                        More <DownOutlined />
                                    </span>
                                ) : (
                                    <span title="Click to collapse">
                                        Less <UpOutlined />
                                    </span>
                                )}
                            </span>
                        )}
                        <div className="noteButtonsContainer">
                            <Tooltip title="Edit note" mouseEnterDelay={0.3} mouseLeaveDelay={0}>
                                <Button icon={<EditOutlined />} size="small" onClick={handleEdit} />
                            </Tooltip>
                            <Tooltip title="Delete note" mouseEnterDelay={0.3} mouseLeaveDelay={0}>
                                <Button
                                    size="small"
                                    icon={<DeleteTwoTone twoToneColor={"red"} />}
                                    onClick={(e) => handleDeleteNote(e, note.id)}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default EditableNote;
