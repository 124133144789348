import React, { FormEvent, MouseEvent, useContext, useEffect, useRef, useState } from "react";
import "../styles/AIChatbot.css";
import { Button, Drawer, Form, Input, InputRef, Tooltip } from "antd";
import {
    axiosInstance,
    handleCopyEvent,
    handlePasteEvent,
    preventZoomOnScrollableElements,
} from "../helpers/nodeHelpers";
import ProblemContext from "../contexts/ProblemContext";
import Prism from "prismjs";
import "prismjs/components/prism-python";
import "prismjs/themes/prism-okaidia.css";
import { DoubleLeftOutlined, DoubleRightOutlined, PlusCircleFilled } from "@ant-design/icons";
import { debouncedAutoSave } from "./editor";
import DOMPurify from "dompurify";
import { BACKEND_URL } from "../config";

interface Conversation {
    id: number;
    name: string;
    updatedAt: Date;
    is_active: boolean;
}

interface MessageContent {
    content: string;
    type: "request" | "response";
    createdAt?: string;
    tempStreamingMessage?: boolean;
}

interface AIChatbotProps {
    leftColumnRef: React.RefObject<HTMLDivElement>;
    rightColumnRef: React.RefObject<HTMLDivElement>;
    openChatbotButtonRef: React.RefObject<HTMLButtonElement>;
}

export function convertToUserLocalTime(dateTimeString?: string) {
    if (!dateTimeString) {
        return "";
    }
    const date = new Date(dateTimeString);
    if (isNaN(date.getTime())) {
        return "Invalid date";
    }
    const dateOptions: Intl.DateTimeFormatOptions = {
        month: "long",
        day: "numeric",
        year: "numeric",
    };
    const options: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };
    const userDate = date.toLocaleDateString([], dateOptions);
    const userTime = date.toLocaleTimeString([], options);
    return `${userDate} at ${userTime}`;
}

const AIChatbot = ({ leftColumnRef, rightColumnRef, openChatbotButtonRef }: AIChatbotProps) => {
    const problemContext = useContext(ProblemContext);
    const { problem } = problemContext!;
    const [showChatbot, setShowChatbot] = useState(false);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [currentConversation, setCurrentConversation] = useState<Conversation>();
    const [messages, setMessages] = useState<MessageContent[]>([
        { content: "Ask me anything!", type: "response" },
    ]);
    const [inputValue, setInputValue] = useState("");
    const [leftPosition, setLeftPosition] = useState(0);
    const [rightPosition, setRightPosition] = useState(0);
    const [collapsed, setCollapsed] = useState(false);
    const [delayRender, setDelayRender] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState<boolean | undefined>();
    const [drawerHeight, setDrawerHeight] = useState(
        window.innerHeight < 700 ? 0.8 * window.innerHeight : 600
    );
    const [messageLoading, setMessageLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const isDraggingRef = useRef(false);
    const startY = useRef(0);
    const startHeight = useRef(0);
    const updateInterval = useRef<NodeJS.Timeout | null>(null);
    const chatInputRef = useRef<InputRef>(null);

    const updateWidths = () => {
        if (leftColumnRef.current) {
            setLeftPosition(leftColumnRef.current.offsetWidth);
        }
        if (rightColumnRef.current) {
            const rightRect = rightColumnRef.current.getBoundingClientRect();
            setRightPosition(document.body.offsetWidth - rightRect.left);
        }
    };

    const closeChatbot = () => {
        setShowChatbot(false);
        debouncedAutoSave("close_chatbot", {}, true);
    };

    const openChatbot = () => {
        setShowChatbot(true);
        debouncedAutoSave("open_chatbot", {}, true);
    };

    const stopUpdateInterval = () => {
        if (updateInterval.current) {
            clearInterval(updateInterval.current);
            updateInterval.current = null;
        }
    };

    const askQuestion = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!inputValue || !problem) {
            return;
        }
        setMessageLoading(true);
        const savedInputValue = inputValue;
        setInputValue("");
        const currentDate = new Date();

        setMessages((prevMessages) => [
            ...prevMessages,
            {
                content: savedInputValue,
                type: "request",
                createdAt: convertToUserLocalTime(currentDate.toISOString()),
            },
            {
                content: "",
                type: "response",
                tempStreamingMessage: true,
            },
        ]);

        chatInputRef.current?.focus();

        try {
            const response = await fetch(`${BACKEND_URL}/api/chatbot/ask-chatbot-question`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "text/event-stream",
                },
                body: JSON.stringify({
                    message: savedInputValue,
                    conversation_id: currentConversation?.id,
                    problem_id: problem.id,
                }),
                credentials: "include",
            });

            const reader = response.body?.getReader();
            const decoder = new TextDecoder();
            let chatResponse = "";

            if (!reader) throw new Error("No reader available, try again.");

            const startUpdateInterval = () => {
                if (updateInterval.current) return;

                updateInterval.current = setInterval(() => {
                    setMessages((prevMessages) =>
                        prevMessages.map((message) =>
                            message.tempStreamingMessage
                                ? {
                                      ...message,
                                      content: chatResponse,
                                  }
                                : message
                        )
                    );
                }, 200);
            };
            reader.read().then(function processText({ done, value }): Promise<void> {
                if (done) {
                    stopUpdateInterval();
                    return Promise.resolve();
                }
                const chunk = decoder.decode(value, { stream: true });
                const lines = chunk.split("\n").filter((line) => line.trim());
                const lastLine = lines[lines.length - 1].trim();
                if (lastLine.startsWith("data:")) {
                    try {
                        const messageData = JSON.parse(lastLine.slice(5));
                        if (messageData.event === "done") {
                            debouncedAutoSave(
                                "chatbot_request",
                                {
                                    request: savedInputValue,
                                    response: messageData.chat_response.content,
                                },
                                true
                            );
                            setMessages((prevMessages) =>
                                prevMessages.map((message) =>
                                    message.tempStreamingMessage
                                        ? {
                                              ...message,
                                              content: messageData.chat_response.content,
                                              tempStreamingMessage: false,
                                              createdAt: convertToUserLocalTime(
                                                  messageData.chat_response.createdAt
                                              ),
                                          }
                                        : message
                                )
                            );

                            if (messageData.updateTitle) {
                                if (conversations.length === 0) {
                                    setConversations([
                                        { ...messageData.conversation, is_active: false },
                                    ]);
                                } else {
                                    setConversations((prevConversations) => {
                                        return prevConversations.map((conversation) =>
                                            conversation.id === messageData.conversation.id
                                                ? {
                                                      ...messageData.conversation,
                                                      is_active: false,
                                                  }
                                                : conversation
                                        );
                                    });
                                }
                                setCurrentConversation(messageData.conversation);
                            }
                            setMessageLoading(false);
                            stopUpdateInterval();
                        } else if (messageData.content) {
                            setMessageLoading(false);
                            chatResponse = messageData.content;
                            startUpdateInterval();
                        } else if (messageData.event === "error") {
                            throw new Error(messageData.message);
                        }
                    } catch (error) {
                        console.log("Failed to parse JSON");
                    }
                }

                return reader.read().then(processText);
            });
        } catch (error) {
            console.error("Error sending message", error);
            stopUpdateInterval();
            setMessages((prevMessages) => {
                const updatedMessages: MessageContent[] = [
                    ...prevMessages,
                    {
                        content:
                            "Sorry, I am unable to process your request, please refresh and try again.",
                        type: "response",
                    },
                ];
                return updatedMessages.map((message) =>
                    message.tempStreamingMessage
                        ? {
                              ...message,
                              tempStreamingMessage: false,
                          }
                        : message
                );
            });

            setInputValue(savedInputValue);
            setMessageLoading(false);
        }
    };

    const loadMessages = async () => {
        if (!currentConversation) {
            return;
        }
        try {
            // fetch messages
            const res = await axiosInstance.get(
                `/api/chatbot/get-messages/${currentConversation.id}`,
                {
                    withCredentials: true,
                }
            );

            setMessages([
                { content: "Ask me anything!", type: "response" },
                ...res.data.messages.map((message: MessageContent) => {
                    const cleanMessageContent = DOMPurify.sanitize(message.content);
                    return {
                        content: cleanMessageContent,
                        type: message.type,
                        createdAt: convertToUserLocalTime(message.createdAt),
                    };
                }),
            ]);
        } catch (error) {
            console.log("Error fetching messages", error);
        }
    };

    const loadConversations = async () => {
        try {
            // fetch conversations
            const res = await axiosInstance.get("/api/chatbot/get-conversations", {
                withCredentials: true,
            });
            const conversations: Conversation[] = res.data.conversations;
            setConversations(
                conversations.map((conversation: Conversation) => ({
                    ...conversation,
                    is_active: false,
                }))
            );
            if (conversations.length > 0) {
                const lastActiveConversation = res.data.conversations.reduce(
                    (prev: Conversation, current: Conversation) => {
                        return new Date(prev.updatedAt) > new Date(current.updatedAt)
                            ? prev
                            : current;
                    }
                );
                setCurrentConversation(lastActiveConversation);
            }
        } catch (error) {
            console.log("Error fetching conversations", error);
        }
    };

    const selectConversation = (conversation: Conversation) => {
        if (conversation.id === currentConversation?.id) {
            return;
        }
        setCurrentConversation(conversation);
    };

    const createConversation = async () => {
        try {
            const tempName = `Conversation at ${new Date().toLocaleTimeString()}`;
            const res = await axiosInstance.post(
                "/api/chatbot/create-new-conversation",
                { name: tempName },
                {
                    withCredentials: true,
                }
            );
            debouncedAutoSave("create_new_conversation", {}, true);
            setConversations((prevConversations) => [...prevConversations, res.data.conversation]);
            setCurrentConversation(res.data.conversation);
        } catch (error) {
            console.log("Error creating conversation", error);
        }
    };

    const resetClosedTooltip = () => {
        setTooltipOpen(false);
        setTimeout(() => {
            setTooltipOpen(undefined);
        }, 10);
    };

    const onMouseDown = (e: MouseEvent) => {
        setIsDragging(true);
        isDraggingRef.current = true;
        startY.current = e.clientY;
        startHeight.current = drawerHeight;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e: any) => {
        if (isDraggingRef.current) {
            const difference = e.clientY - startY.current;
            const newHeight = startHeight.current - difference;
            setDrawerHeight(Math.max(newHeight, 350));
        }
    };

    const onMouseUp = () => {
        setIsDragging(false);
        isDraggingRef.current = false;
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    };

    useEffect(() => {
        if (currentConversation) {
            loadMessages();
            setConversations((prevConversations) => {
                return prevConversations?.map((conversation) => {
                    return {
                        ...conversation,
                        is_active: conversation.id === currentConversation.id,
                    };
                });
            });
        }
    }, [currentConversation]);

    useEffect(() => {
        if (messages) {
            const codeElements = document.querySelectorAll("code.language-python");

            codeElements.forEach((element) => {
                Prism.highlightElement(element);
            });

            const chatbotMessagesContainer = document.querySelector(".chatbotMessagesContainer");
            if (chatbotMessagesContainer) {
                chatbotMessagesContainer.scrollTop = chatbotMessagesContainer.scrollHeight;
            }
        }
    }, [messages, showChatbot]);

    useEffect(() => {
        setTimeout(() => {
            setDelayRender(collapsed);
        }, 200);
    }, [collapsed]);

    useEffect(() => {
        const chatbotOverallContainer = document.querySelectorAll(".chatbotOverallContainer");
        if (chatbotOverallContainer) {
            preventZoomOnScrollableElements(chatbotOverallContainer);
        }
        loadConversations();

        const leftObserver = new ResizeObserver(updateWidths);
        const rightObserver = new ResizeObserver(updateWidths);

        if (leftColumnRef.current) {
            leftObserver.observe(leftColumnRef.current);
        }

        if (rightColumnRef.current) {
            rightObserver.observe(rightColumnRef.current);
        }

        return () => {
            if (leftColumnRef.current) {
                leftObserver.disconnect();
            }
            if (rightColumnRef.current) {
                rightObserver.disconnect();
            }
        };
    }, []);

    return (
        <div className="chatbotOverallContainer">
            <Drawer
                placement="bottom"
                classNames={{ body: "chatbotBody" }}
                styles={{
                    header: {
                        display: "none",
                    },
                    wrapper: {
                        left: `${leftPosition}px`,
                        right: `${rightPosition}px`,
                        transition: isDragging ? "none" : "",
                    },
                }}
                height={drawerHeight}
                open={showChatbot}
                onClose={closeChatbot}
                className={isDragging ? "chatbotDrawerDragging" : ""}
            >
                <div className="chatbotContainer">
                    <div
                        className="resizeBar"
                        title="Drag to resize"
                        onMouseDown={onMouseDown}
                    ></div>
                    <div className="chatbotHeader">
                        <Button className="chatbotHeaderCloseButton" onClick={closeChatbot}>
                            Close Chatbot
                        </Button>
                        <h2 className="chatbotHeaderTitle">AI Chatbot</h2>
                        <div className="spacer"></div>
                    </div>

                    <div className="chatbotMainContent">
                        <div
                            className={`chatbotConversationContainer ${
                                collapsed ? "collapsed" : ""
                            }`}
                        >
                            {!collapsed && !delayRender && (
                                <>
                                    <h3>Conversations</h3>
                                    <Button
                                        className="addNewConversationBtn"
                                        title="Create new conversation"
                                        onClick={createConversation}
                                    >
                                        New Chat{" "}
                                        <PlusCircleFilled className="newConversationPlusIcon" />
                                    </Button>
                                    <ul className="conversation-list">
                                        {conversations?.map((conversation, index) => (
                                            <li
                                                tabIndex={0}
                                                role="button"
                                                key={conversation.id || index}
                                                className={`conversation-list-item ${
                                                    conversation.is_active
                                                        ? "active-conversation"
                                                        : ""
                                                }`}
                                                title={conversation.name}
                                                onClick={() => selectConversation(conversation)}
                                            >
                                                {conversation.name}
                                            </li>
                                        ))}
                                        {conversations.length === 0 && (
                                            <li className="no-conversations-msg">
                                                No conversations yet
                                            </li>
                                        )}
                                    </ul>
                                </>
                            )}
                            <Tooltip
                                placement="top"
                                title={collapsed ? "Expand Sidebar" : "Collapse Sidebar"}
                                open={tooltipOpen}
                                mouseEnterDelay={0}
                                mouseLeaveDelay={0.5}
                            >
                                <Button
                                    className={`collapseConversationBarBtn`}
                                    icon={
                                        collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />
                                    }
                                    onClick={() => {
                                        resetClosedTooltip();
                                        setCollapsed((prev) => !prev);
                                    }}
                                ></Button>
                            </Tooltip>
                            {collapsed && delayRender && (
                                <div className="collapsedConversationBarPlaceholder">
                                    <p>Expand to view conversations</p>
                                </div>
                            )}
                        </div>
                        <div className="chatbotMessagesAndInputContainer">
                            <div className="chatbotMessagesAndInputInternalPositioningContainer">
                                <ul
                                    className="chatbotMessagesContainer"
                                    onCopy={() => handleCopyEvent("chatbot messages")}
                                >
                                    {messages.map((message, index) => {
                                        return (
                                            message.content && (
                                                <li
                                                    key={index}
                                                    className={`message-list-item ${
                                                        message.type === "response"
                                                            ? "response-message"
                                                            : "request-message"
                                                    }${
                                                        message.createdAt
                                                            ? " addPaddingForTimestamp"
                                                            : ""
                                                    }`}
                                                >
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: message.content,
                                                        }}
                                                    ></div>
                                                    {message.createdAt && (
                                                        <span className="messageTimeStamp">
                                                            {`${message.createdAt}`}
                                                        </span>
                                                    )}
                                                </li>
                                            )
                                        );
                                    })}
                                    {messageLoading && (
                                        <li
                                            key={"chatbotLoadingIndicator"}
                                            className="message-list-item response-message"
                                        >
                                            <div className="typing-indicator">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                                <Form
                                    className="chatbotInputContainer"
                                    onSubmitCapture={askQuestion}
                                >
                                    <Input
                                        type="text"
                                        id="chatbotInput"
                                        autoComplete="off"
                                        className="chatbotInput"
                                        placeholder="Type your question here..."
                                        ref={chatInputRef}
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        onPaste={(e) => handlePasteEvent("chatbot input", e)}
                                    />
                                    <Button
                                        name="chatbotSendButton"
                                        type="primary"
                                        htmlType="submit"
                                        className="chatbotSendButton"
                                    >
                                        Send
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
            <Button ref={openChatbotButtonRef} className="openChatbotBtn" onClick={openChatbot}>
                Open AI Chatbot
            </Button>
        </div>
    );
};

export default AIChatbot;
