import React, { useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "@uiw/react-codemirror";
import { python } from "@codemirror/lang-python";
import "../styles/RightColumn.css";
import { handleCopyEvent } from "../helpers/nodeHelpers";

interface CodePreviewProps {
    codePreview: string;
}

const CodePreview = ({ codePreview }: CodePreviewProps) => {
    const { lineWrapping, editable } = EditorView;
    const [previousCodePreview, setPreviousCodePreview] = useState<string>("");

    const findFirstDifferenceIndex = (str1: string, str2: string) => {
        for (let i = 0; i < Math.max(str1.length, str2.length); i++) {
            if (str1[i] !== str2[i]) {
                return i;
            }
        }
        return -1;
    };

    const scrollToFirstChange = (oldText: string, newText: string) => {
        const div: HTMLDivElement | null = document.querySelector(
            ".codePreviewInnerContainer .cm-scroller"
        );
        const firstDiffIndex = findFirstDifferenceIndex(oldText, newText);

        if (div && firstDiffIndex !== -1) {
            const tempDiv = document.createElement("div");
            tempDiv.style.visibility = "hidden";
            tempDiv.style.position = "absolute";
            tempDiv.style.width = div.offsetWidth + "px";
            tempDiv.innerHTML = newText.substring(0, firstDiffIndex).replace(/\n/g, "<br>");

            document.body.appendChild(tempDiv);
            const offsetTop = tempDiv.offsetHeight;

            const cmContent = div.querySelector(".cm-content");

            if (cmContent) {
                if (offsetTop > div.offsetHeight - 110) {
                    div.scrollTop = offsetTop;
                } else {
                    div.scrollTop = 0;
                }
            }
            document.body.removeChild(tempDiv);
        } else {
            console.log("No differences found");
        }
    };

    useEffect(() => {
        if (codePreview !== previousCodePreview && previousCodePreview !== "") {
            scrollToFirstChange(previousCodePreview, codePreview);
        }
        setPreviousCodePreview(codePreview);
    }, [codePreview]);
    return (
        <div className="rightColumnHalfContainer topHalf">
            <div className="codePreviewOuterContainer">
                <h2 className="codePreviewTitle">Code Preview</h2>
                <CodeMirror
                    onPointerDown={(e) => e.stopPropagation()}
                    className="codePreviewInnerContainer"
                    height="100%"
                    value={codePreview || "# Your code will appear here"}
                    theme={"dark"}
                    extensions={[python(), lineWrapping, editable.of(false)]}
                    onCopy={() => handleCopyEvent("code preview window")}
                />
            </div>
        </div>
    );
};

export default CodePreview;
